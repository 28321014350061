<template>
  <div class="website-tab-overview">
    <payment-acquirer-add-new
      v-model="isAddNewPaymentAcquirerSidebarActive"
      @refetch-data="fetchPaymentAcquirer"
    ></payment-acquirer-add-new>
    <payment-acquirer-edit
      :is-payment-acquirer-edit-dialog-open.sync="isPaymentAcquirerEditDialogOpen"
      :payment-acquirer-data="selectedPaymentAcquirer"
      @refetch-data="fetchPaymentAcquirer"
    ></payment-acquirer-edit>
    <v-card class="mb-7">
      <v-card-title> Payment Acquirer List </v-card-title>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="add-new-website mb-4 me-3"
            @click.stop="isAddNewPaymentAcquirerSidebarActive = !isAddNewPaymentAcquirerSidebarActive"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add New Payment Acquirer</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table :headers="tableColumns" :item-class="paymentAcquirerItemClass" :items="paymentAcquirersListTable" :loading="loading" hide-default-footer>
        <template #[`item.logo`]="{ item }">
          <div><img class="acquirer-logo-img" :src="item.logo" :alt="item.name"/></div>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template #activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                @click="
                  selectedPaymentAcquirer = item
                  isPaymentAcquirerEditDialogOpen = !isPaymentAcquirerEditDialogOpen
                "
              >
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="deletePaymentAcquirer(item)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiFileDocumentOutline,
} from '@mdi/js';
import { ref } from '@vue/composition-api';
import PaymentAcquirerEdit from '@/views/payments/payment-acquirers/PaymentAcquirerEdit';
import PaymentAcquirerAddNew from '@/views/payments/payment-acquirers/PaymentAcquirerAddNew';
import usePaymentAcquirerList from '@/views/payments/payment-acquirers/usePaymentAcquirerList';
import Vue from 'vue';
import store from '@/store';

export default {
  components: {
    PaymentAcquirerEdit,
    PaymentAcquirerAddNew,
  },
  setup() {
    const { paymentAcquirersListTable, tableColumns, loading, fetchPaymentAcquirer } = usePaymentAcquirerList();

    const selectedPaymentAcquirer = null;
    const isPaymentAcquirerEditDialogOpen = ref(false);
    const isAddNewPaymentAcquirerSidebarActive = ref(false);

    const paymentAcquirerItemClass = () => {
      return 'payment-acquirer-item';
    };

    const deletePaymentAcquirer = (paymentAcquirer) => {
      Vue.$confirm({
        message: `Are you sure you want to delete ${paymentAcquirer.name}?`,
        button: {
          no: 'No',
          yes: 'Yes',
        },
        callback: confirm => {
          if (confirm) {
            store
              .dispatch('paymentAcquirer/deletePaymentAcquirer', paymentAcquirer.id)
              .then(() => fetchPaymentAcquirer());
          }
        },
      });
    };

    return {
      selectedPaymentAcquirer,
      tableColumns,
      paymentAcquirersListTable,
      isPaymentAcquirerEditDialogOpen,
      isAddNewPaymentAcquirerSidebarActive,
      fetchPaymentAcquirer,
      loading,
      paymentAcquirerItemClass,
      deletePaymentAcquirer,
      icons: {
        mdiDotsVertical,
        mdiFileDocumentOutline,
        mdiDeleteOutline,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.acquirer-logo-img {
  height: 50px;
}
</style>
