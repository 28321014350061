var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"website-tab-overview"},[_c('payment-acquirer-add-new',{on:{"refetch-data":_vm.fetchPaymentAcquirer},model:{value:(_vm.isAddNewPaymentAcquirerSidebarActive),callback:function ($$v) {_vm.isAddNewPaymentAcquirerSidebarActive=$$v},expression:"isAddNewPaymentAcquirerSidebarActive"}}),_c('payment-acquirer-edit',{attrs:{"is-payment-acquirer-edit-dialog-open":_vm.isPaymentAcquirerEditDialogOpen,"payment-acquirer-data":_vm.selectedPaymentAcquirer},on:{"update:isPaymentAcquirerEditDialogOpen":function($event){_vm.isPaymentAcquirerEditDialogOpen=$event},"update:is-payment-acquirer-edit-dialog-open":function($event){_vm.isPaymentAcquirerEditDialogOpen=$event},"refetch-data":_vm.fetchPaymentAcquirer}}),_c('v-card',{staticClass:"mb-7"},[_c('v-card-title',[_vm._v(" Payment Acquirer List ")]),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('v-spacer'),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('v-btn',{staticClass:"add-new-website mb-4 me-3",attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.isAddNewPaymentAcquirerSidebarActive = !_vm.isAddNewPaymentAcquirerSidebarActive}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Add New Payment Acquirer")])],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"item-class":_vm.paymentAcquirerItemClass,"items":_vm.paymentAcquirersListTable,"loading":_vm.loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.logo",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('img',{staticClass:"acquirer-logo-img",attrs:{"src":item.logo,"alt":item.name}})])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.selectedPaymentAcquirer = item
                _vm.isPaymentAcquirerEditDialogOpen = !_vm.isPaymentAcquirerEditDialogOpen}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentOutline)+" ")]),_c('span',[_vm._v("Edit")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.deletePaymentAcquirer(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v("Delete")])],1)],1)],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }