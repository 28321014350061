import store from '@/store';
import { ref } from '@vue/composition-api';
import Vue from 'vue';

export default function usePaymentAcquirerList() {
  const paymentAcquirersListTable = ref([]);

  const tableColumns = [
    { text: 'Name', value: 'name', sortable: false },
    { text: 'Logo', value: 'logo', sortable: false },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ];

  const loading = ref(false);

  // fetch data
  const fetchPaymentAcquirer = () => {
    store
      .dispatch('paymentAcquirer/fetchPaymentAcquirers')
      .then(response => {
        paymentAcquirersListTable.value = response.data;
        loading.value = false;
      })
      .catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Payment Acquirers',
          text: 'Cannot fetch payment acquirers',
        });
      });
  };

  fetchPaymentAcquirer();

  return {
    paymentAcquirersListTable,
    tableColumns,
    loading,
    fetchPaymentAcquirer,
  };
}
